import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 10,
        borderRadius: 5,
    },
    colorPrimary: {
        // backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
        backgroundColor: "#ff5e5e",
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#089bab',
    },
}))(LinearProgress);

// Inspired by the former Facebook spinners.

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
    barStyles: {
        borderTopRightRadius: "0 !important",
        borderBottomRightRadius: "0 !important",
    },
});

export default function CustomizedProgressBars({ value }) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <BorderLinearProgress classes={{ bar1Determinate: classes.barStyles }} variant="determinate" value={value} />
        </div>
    );
}
