import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import PropTypes from 'prop-types';

class DoughnutChart extends React.Component {
    render() {
        const { width, height, data } = this.props
        return (
            <Doughnut
                data={data}
                width={width}
                height={height}
                options={{
                    legend: {
                        display: false,
                        position: "bottom",
                        align: "start"
                    },
                    responsive: true,
                    maintainAspectRatio: false
                }}
            />
        );
    }
};

DoughnutChart.defaultProps = {
    data: {
        labels: [
            'CKD',
            'PD'
        ],
        datasets: [{
            data: [73, 17],
            backgroundColor: [
                '#089bab',
                '#f78b1a'
            ],
            hoverBackgroundColor: [
                '#089bab',
                '#f78b1a'
            ]
        }]
    },
    width: null,
    height: null
}
DoughnutChart.propTypes = {
    data: PropTypes.object,
    width: PropTypes.number,
    height: PropTypes.number

}
export default DoughnutChart;