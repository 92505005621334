import React from "react";
// import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
//import Loadable from "react-loadable";
import { withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
//import Grid from '@material-ui/core/Grid';
import { withStyles } from "@material-ui/core/styles";
import { mapDispatchToProps } from "../../../../../../ui-utils/commons";
import { Button,  Paper } from "@material-ui/core";
import { ArrowBack,  ArrowForwardIos } from "@material-ui/icons";
import Divider from '@material-ui/core/Divider';
//import InfoIcon from "@material-ui/icons/Info";
//import ListItem from "@material-ui/core/ListItem";
//import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Tabs from "../../../../../../ui-molecules/Tabs";
import LinearProgress from '../../../../../../ui-molecules/LinearProgress';
import DoughnutChart from '../../../../../../ui-molecules/DoughnutChart/DoughnutChart';
import PieChart from '../../../../../../ui-molecules/PieChart';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const donutData = {
  labels: [
    'CKD',
    'PD'
  ],
  datasets: [{
    data: [95, 5],
    backgroundColor: [
      '#089bab',
      '#ff5e5e'
    ],
    hoverBackgroundColor: [
      '#089bab',
      '#f78b1a'
    ]
  }]
}
const donutData1 = {
  labels: [
    'Swollen Legs',
    'Dizziness',
    'Bloating'
  ],
  datasets: [{
    data: [50, 25, 25],
    backgroundColor: [
      '#a6bbcf',
      '#a2d3e3',
      '#aaa6da'
    ],
    hoverBackgroundColor: [
      '#089bab',
      '#f78b1a'
    ]
  }]
}

const styles = {
  root: {
    margin: "32px"
  },
  container: {
    boxSizing: 'border-box',
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  item: {
    boxSizing: 'border-box',
    margin: '0',

  },
  select: {
    width: "160px",
    height: "40px",
    borderRadius: "12px",
    // border: "solid 2px #d7d7d7",
    // border: "none",
    marginRight: "16px"
  },
  button: {
    height: "40px",
    borderRadius: "12px",
    width: "90px"
  },
  header: {
    display: "flex"
  },
  infoElement: {
    marginLeft: "40px",
    display: "flex"
  },
  headerButtons: {
    marginLeft: "auto"
  },
  headerBtn: {
    width: "40px",
    height: "40px",
    borderRadius: "12px",
    backgroundColor: "#ceebef",
    marginRight: "16px"
  },
  buttonText: {
    width: "37px",
    height: "24px",
    
    fontSize: "16px",
    fontWeight: "600",
    linHeight: "1.5",
    letterSpacing: "normal",
    color: "#089bab",
    textTransform: "capitalize",
  },
  arrow: {
    width: "14px",
    height: "13px",
    borderRadius: "1px",
    color: "#089bab",
  },
  summaryButton: {
    height: "40px",
    borderRadius: "12px",
    textTransform: "capitalize",
  },
  patientDetails: {
    height: "40px",
    fontFamily: "Poppins",
    fontSize: "32px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.25",
    letterSpacing: "normal",
    color: "#a2a0a0",
  },
  arrowForward: {
    width: "32px",
    height: "32px",
    color: "#a2a0a0",
    marginLeft: "8px",
    marginTop: "4px"
  },
  patientName: {
    fontFamily: "Poppins",
    fontSize: "32px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.25",
    letterSpacing: "normal",
    color: "#333333"
  },
  paperItemLeft: {
    maxHeight: "auto",
    borderRadius: "32px",
    boxShadow: "0 2px 20px 0 rgba(36, 36, 36, 0.16)",
    padding: "16px 32px",
    flex: 1
  },
  paperItemMid: {
    padding: "24px",
    maxHeight: "auto",
    borderRadius: "32px",
    boxShadow: "0 2px 20px 0 rgba(36, 36, 36, 0.16)",
  },
  paperItemMid1: {
    display: "flex",
    padding: "24px",
    maxHeight: "auto",
    borderRadius: "32px",
    boxShadow: "0 2px 20px 0 rgba(36, 36, 36, 0.16)",
  },
  paperItemRight: {
    padding: "24px",
    maxHeight: "auto",
    borderRadius: "32px",
    boxShadow: "0 2px 20px 0 rgba(36, 36, 36, 0.16)",
    marginTop: "24px",
    marginLeft: "32px",
    flex: 1
  },
  paperItemRight1: {
    display: "flex",
    //marginTop: "32px",
    padding: "24px",
    maxHeight: "auto",
    borderRadius: "32px",
    boxShadow: "0 2px 20px 0 rgba(36, 36, 36, 0.16)",
    marginTop: "24px",
    marginLeft: "32px",
    flex: 1
  },
  profile: {
    width: "255px",
    height: "32px",
    fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    color: "#333333",
    marginLeft: "32px",
    paddingTop: "32px",
  },
  editIcon: {
    width: "24px",
    height: "24px",
    color: "#a2a0a0",
  },
  deleteIcon: {
    width: "24px",
    height: "24px",
    color: "#a2a0a0"
  },
  icons: {
    display: "flex"
  },
  cardSubContents: {
    display: "flex",
    height: "56px",
    maxWidth: "196px",
    alignItems: "center",
    justifyContent: "space-between"
  },
  patientId: {
    width: "69px",
    height: "24px",
    
    fontSize: "15px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.6",
    letterSpacing: "normal",
    color: "#a2a0a0",
    marginLeft: "32px",
    paddingTop: "16px"
  },
  name: {
    fontSize: "15px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.6",
    letterSpacing: "normal",
    color: "#a2a0a0",
  },
  divider: {
    width: "100%",
    margin: "0 auto"
  },
  age: {
    width: "43px",
    height: "24px",
    
    fontSize: "15px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.6",
    letterSpacing: "normal",
    color: "#a2a0a0",
    marginLeft: "32px",
    paddingTop: "16px"
  },
  itemButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  subContentName: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  patientDetailsSection: {
    marginTop: "28px",
    display: "flex"
  },
  patientColumn1: {
    maxWidth: "100%",
    flex: 0.6
  },
  patientColumn2: {
    maxWidth: "100%",
    flex: 1,
    marginLeft: "20px"
  },
  patientColumn3: {
    flex: 1,
    maxWidth: "100%"
  },
  patientColumnHeader: {
    display: "flex",
    justifyContent: "space-between",
    paddingRight: "34px",
    marginBottom: "25px",
  },
  headerText: {
    fontSize: "24px",
    fontWeight: 500
  },
  blueLine: {
    width: "4px",
    height: "324px",
    opacity: 0.2,
    borderRadius: "2px",
    backgroundColor: "#089bab",
    marginLeft: "8px"
  },
  boldLabel: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#333"
  },
  bpGraph: {
    display: "flex",
    '& div': {
      flex: 1
    }
  },
  linearPercent: {
    fontSize: "15px",
    fontWeight: "bold",
    color: "#089bab",
    position: "absolute",
    right: "22px"
  },
  linearPercent1: {
    color: "#ff5e5e",
    fontSize: "15px",
    fontWeight: "bold",
  },
  lightText: {
    fontSize: "15px",
    color: "#a2a0a0",
    lineHeight: 1.6
  },
  boldText: {
    fontSize: "15px",
    fontWeight: "bold",
    color: "#333",
    lineHeight: 1.6
  },
  subLabel: {
    fontSize: "16px",
    fontWeight: 500,
    marginLeft: "8px"
  },
  flexTexts: {
    display: "flex",
    marginTop: "14px",
    '& p': {
      marginRight: "24px"
    }
  },
  flexTexts1: {
    display: "flex",
    marginTop: "14px",
    '& p': {
      marginRight: "14px"
    }
  },
  relative: {
    position: "relative"
  },
  doughnutPercent: {
    alignSelf: "center",
    position: "absolute",
    right: "45px",
    fontSize: "15px",
    fontWeight: "bold",
    color: "#089bab"
  },
  generateBtn: {
    width: "194px",
    height: "40px",
    borderRadius: "12px",
    backgroundColor: "#089bab",
    fontSize: "16px",
    fontWeight: "600",
    fontStretch: "normal",
    letterSpacing: "normal",
    color: "#ffffff ",
    textTransform: "none",
    '&:hover': {
      backgroundColor: '#089bab',
    },
  },
  therapyChart: {
    display: "flex",
    marginTop: "14px",
    justifyContent: "space-between"
  },
  therapyText: {
    marginLeft: "16px",
    '& p': {
      fontSize: "15px",
      lineHeight: 1.6,
      fontWeight: "bold"
    }
  },
  medicationNotification: {
    position: "absolute",
    top: "-18%",
    left: "68%",
    width: "14px",
    height: "14px",
    borderRadius: "50%",
    backgroundColor: "#ceebef",
    color: "#089bab",
    padding: "1px",
    fontSize: "11px",
    fontWeight: 600
  },
  symptomColor: {
    width: "16px",
    height: "16px",
    backgroundColor: "#a6bbcf",
    borderRadius: "50%",
    marginRight: "8px"
  },
  flexCenter: {
    display: "flex",
    alignItems: "center"
  },
  flexBetween: {
    display: "flex",
    justifyContent: "space-between"
  },
  imagePlaceholder: {
    width: "184px",
    height: "158px",
    borderRadius: "32px",
    backgroundColor: "#ffffff",
    border: "1px solid grey",
    marginLeft: "29px"
  },
  selectFocus: {
    background: 'none !important'
}
}

const PatientDetails = ({ classes }) => {
  return (
    <div className={classes.patientDetailsSection}>
      <div className={classes.patientColumn1}>
        <div className={classes.patientColumnHeader}>
          <Typography className={classes.headerText}>Profile</Typography>
          <Button><img alt="edit icon" src="ui-assets/images/awak-ic-24-px-edit-s.svg" /></Button>
        </div>
        <div className={classes.header}>
          <Paper className={classes.paperItemLeft}>
            <div container className={classes.cardSubContents}>
              <ListItemText className={classes.name}>First Name</ListItemText>
              <ListItemText className={classes.subContentName}>Tania</ListItemText>
            </div>
            <Divider className={classes.divider} variant='middle' />
            <div container className={classes.cardSubContents}>
              <ListItemText className={classes.name}>Last Name</ListItemText>
              <ListItemText className={classes.subContentName}>Perfilyeva</ListItemText>
            </div>
            <Divider className={classes.divider} variant='middle' />
            <div container className={classes.cardSubContents}>
              <ListItemText className={classes.name}>Age</ListItemText>
              <ListItemText className={classes.subContentName}>32 y.o</ListItemText>
            </div>
            <Divider className={classes.divider} variant='middle' />
            <div container className={classes.cardSubContents}>
              <ListItemText className={classes.name}>Gender</ListItemText>
              <ListItemText className={classes.subContentName}>Female</ListItemText>
            </div>
            <Divider className={classes.divider} variant='middle' />
            <div container className={classes.cardSubContents}>
              <ListItemText className={classes.name}>Height</ListItemText>
              <ListItemText className={classes.subContentName}>167 cm</ListItemText>
            </div>
            <div container className={classes.cardSubContents}>
              <ListItemText className={classes.name}>High Priority</ListItemText>
              <ListItemText className={classes.subContentName}>Yes</ListItemText>
            </div>
            <Divider className={classes.divider} variant='middle' />
            <div container className={classes.cardSubContents}>
              <ListItemText className={classes.name}>Type</ListItemText>
              <ListItemText className={classes.subContentName}>CKD</ListItemText>
            </div>
            <Divider className={classes.divider} variant='middle' />
            <div container className={classes.cardSubContents}>
              <ListItemText className={classes.name}>CKD Stage</ListItemText>
              <ListItemText className={classes.subContentName}>5</ListItemText>
            </div>
            <Divider className={classes.divider} variant='middle' />
            <div container className={classes.cardSubContents}>
              <ListItemText className={classes.name}>eGFR</ListItemText>
              <ListItemText className={classes.subContentName}>27 ml/min</ListItemText>
            </div>
            <Divider className={classes.divider} variant='middle' />
            <div container className={classes.cardSubContents}>
              <ListItemText className={classes.name}>Damages</ListItemText>
              <ListItemText className={classes.subContentName}>Diabetes</ListItemText>
            </div>
          </Paper>
          <div className={classes.blueLine} />
        </div>
      </div>
      <div className={classes.patientColumn2}>
        <div className={classes.patientColumnHeader}>
          <Typography className={classes.headerText}>Overview</Typography>
        </div>
        <Paper className={classes.paperItemMid}>
          <Typography className={classes.boldLabel}>Blood Pressure</Typography>
          <div className={classes.bpGraph}>
            <div className={classes.relative} style={{ marginRight: "30px" }}>
              <Typography style={{ margin: "8px 0" }} className={classes.subLabel}>SYS (Lim.: 110–130)</Typography>
              <LinearProgress value={90} />
              <Typography className={classes.linearPercent}>90%</Typography>
              <div style={{ marginTop: "26px" }}>
                <Typography className={classes.lightText}>Max. 145 mmHg</Typography>
                <Typography className={classes.boldText}>Avg. 125 mmHg</Typography>
                <Typography className={classes.lightText}>Min. 120 mmHg</Typography>
              </div>
            </div>
            <div className={classes.relative}>
              <Typography style={{ margin: "8px 0" }} className={classes.subLabel}>DIA (Lim.: 75–90)</Typography>
              <LinearProgress value={95} />
              <Typography className={classes.linearPercent}>95%</Typography>
              <div style={{ marginTop: "26px" }}>
                <Typography className={classes.lightText}>Max. 145 mmHg</Typography>
                <Typography className={classes.boldText}>Avg. 85 mmHg</Typography>
                <Typography className={classes.lightText}>Min. 78 mmHg</Typography>
              </div>
            </div>
          </div>
        </Paper>
        <Paper style={{ marginTop: "32px" }} className={`${classes.paperItemMid1} ${classes.relative}`}>
          <div>
            <span className={classes.header}>
              <Typography className={classes.boldLabel}>Weight</Typography>
              <Typography className={classes.subLabel}>(Lim.: 72–80)</Typography></span>
            <div className={classes.flexTexts}>
              <Typography className={classes.lightText}>Min. 72 kg</Typography>
              <Typography className={classes.boldText}>Avg. 75 kg</Typography>
              <Typography className={classes.lightText}>Max. 79 kg</Typography>
            </div>
          </div>
          <div style={{ marginLeft: "auto" }}>
            <DoughnutChart width={76} height={76} data={donutData} />
          </div>
          <Typography className={classes.doughnutPercent}>95%</Typography>
        </Paper>
        <Paper style={{ marginTop: "32px" }} className={`${classes.paperItemMid1} ${classes.relative}`}>
          <div>
            <span className={classes.header}>
              <Typography className={classes.boldLabel}>Urinary Frequency</Typography>
              <Typography className={classes.subLabel}>(Lim.: 500–850)</Typography></span>
            <div className={classes.flexTexts1}>
              <Typography className={classes.lightText}>Min. 360 ml</Typography>
              <Typography className={classes.boldText}>Avg. 720 ml</Typography>
              <Typography className={classes.lightText}>Max. 1010 ml</Typography>
            </div>
          </div>
          <div style={{ marginLeft: "auto" }}>
            <DoughnutChart width={76} height={76} data={donutData} />
          </div>
          <Typography className={classes.doughnutPercent}>95%</Typography>
        </Paper>
      </div>
      <div className={classes.patientColumn3}>
        <FormControl variant="outlined">
          <Select classes={{
            select: classes.selectFocus
          }} id="input1" className={classes.select} value="All Statuses">
            <MenuItem value="All Statuses">Last 30 days</MenuItem>
            <MenuItem value="Active">Last 60 days</MenuItem>
            <MenuItem value="Pending">Last 90 days</MenuItem>
          </Select>
        </FormControl>
        <Button className={classes.generateBtn}>Generate Report</Button>
        <Paper className={`${classes.paperItemRight}`}>
          <span style={{ justifyContent: "space-between" }} className={classes.header}>
            <Typography className={classes.boldLabel}>Therapy and Medication</Typography>
            <div>
              <button className={classes.relative}><img alt="bell icon" src="ui-assets/images/awak-ic-24-px-medications.svg" />
                <div className={classes.medicationNotification}>1</div>
              </button>
              <button className={classes.relative} style={{ marginLeft: "14px" }}><img alt="chat icon" src="ui-assets/images/awak-ic-24-px-therapies.svg" />
                <div className={classes.medicationNotification}>3</div>
              </button>
            </div>
          </span>
          <div className={classes.therapyChart}>
            <div className={classes.header}>
              <PieChart width={72} height={72} />
              <div className={classes.therapyText}>
                <Typography style={{ color: "#a2a0a0" }}>10%</Typography>
                <Typography>Unlogged Therapy</Typography>
              </div>
            </div>
            <div className={classes.header}>
              <PieChart width={72} height={72} />
              <div className={classes.therapyText}>
                <Typography style={{ color: "#a2a0a0" }}>17%</Typography>
                <Typography style={{ fontWeight: 500 }}>Unlogged Medicine</Typography>
              </div>
            </div>
          </div>
        </Paper>
        <Paper style={{ marginTop: "32px" }} className={`${classes.paperItemRight1}`}>
          <div style={{ marginTop: "12px" }}>
            <Typography style={{ marginBottom: "8px" }} className={classes.boldLabel}>Symptoms</Typography>
            <div>
              <div style={{ justifyContent: "space-between" }} className={`${classes.header}`}>
                <div className={classes.flexCenter}>
                  <div className={classes.symptomColor}></div>
                  <Typography className={classes.name}>
                    Swollen Legs:</Typography>
                </div>
                <Typography className={classes.boldText}>2 episodes</Typography>
              </div>
              <div style={{ justifyContent: "space-between" }} className={`${classes.header}`}>
                <div className={classes.flexCenter}>
                  <div className={classes.symptomColor}></div>
                  <Typography className={classes.name}>Dizziness:</Typography>
                </div>
                <Typography className={classes.boldText}>2 episodes</Typography>
              </div>
              <div style={{ justifyContent: "space-between" }} className={`${classes.header}`}>
                <div className={classes.flexCenter}>
                  <div className={classes.symptomColor}></div>
                  <Typography className={classes.name}>Bloating:</Typography>
                </div>
                <Typography className={classes.boldText}>2 episodes</Typography>
              </div>
            </div>
          </div>
          <div style={{ marginLeft: "auto" }} className={`${classes.header}`}>
            <DoughnutChart width={110} height={110} data={donutData1} />
          </div>
        </Paper>
        <Paper style={{ marginTop: "32px" }} className={`${classes.paperItemRight1}`}>
          <div style={{ flex: 1 }}>
            <Typography style={{ marginBottom: "8px" }} className={classes.boldLabel}>Exit Site</Typography>
            <div>
              <LinearProgress value={92} />
              <span className={classes.flexBetween} >
                <Typography style={{
                  fontSize: "12px",
                  lineHeight: 1.33
                }}>Anormality Reported</Typography>
                <Typography className={classes.linearPercent1}>90%</Typography>
              </span>
            </div>
            <div style={{ marginTop: "23px" }} className={classes.flexBetween}>
              <img alt="placeholder" src="ui-assets/images/awak-ic-24-px-pic.svg" />
              <Typography className={classes.lightText}>Taken on 23 Aug 20</Typography>
            </div>
          </div>
          <div className={classes.imagePlaceholder} />
        </Paper>
      </div>
    </div>
  )
}
class PatientOverview extends React.Component {

  render() {
    console.log('LOGGED');
    const { classes, history } = this.props;
    const backToSummary = () => {
      history.push('/user-home/patients')
    }
    return (
      <div className={classes.root}>
        <div className={classes.header}>
          <Button variant="outlined" color="primary" onClick={backToSummary} className={classes.button}>
            <ArrowBack className={classes.arrow} />
            <Typography className={classes.buttonText}>Back</Typography>
          </Button>
          <div className={classes.infoElement}>
            <Typography className={classes.patientName}>Philippa Davis</Typography>
            <ArrowForwardIos className={classes.arrowForward} />
            <Typography className={classes.patientDetails}>CKD 5, Female, 52 years old</Typography>
          </div>
          <div className={classes.headerButtons}>
            <button
              className={classes.headerBtn}
              variant="contained"
              color="primary" >
              <img src="ui-assets/images/doc.svg" alt="" />
            </button>
            <button
              className={classes.headerBtn}
            >
              <img src="ui-assets/images/awak-ic-24-px-chat.svg" alt="" />
            </button>
            <button
              className={classes.headerBtn}
            >
              <img src="ui-assets/images/awak-ic-24-px-bell.svg" alt="" />
            </button>
          </div>
        </div>
        <div className={classes.tabs}>
          <Tabs
            tabsStyle={{
              maxWidth: "850px",
              marginTop: "20px"
            }}
            tabStyle={{
              fontSize: "16px",
              fontWeight: "bold",
              minWidth: "fit-content"
            }}
            components={[{
              label: "Overview",
              component: <PatientDetails classes={classes} />
            }, {
              label: "Vitals",
              component: <div>Vitals</div>
            }, {
              label: "Therapy",
              component: <div>Therapy</div>
            }, {
              label: "Medication",
              component: <div>Medication</div>
            }, {
              label: "Symptoms",
              component: <div>Symptoms</div>
            }, {
              label: "Communication",
              component: <div>Communication</div>
            }, {
              label: "Diet",
              component: <div>Diet</div>
            }, {
              label: "Notes",
              component: <div>Notes</div>
            }
            ]}>
          </Tabs>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ screenConfiguration }) => {
  //const { preparedFinalObject = {} } = screenConfiguration;
  return {}
};

export default connect(
  mapStateToProps, mapDispatchToProps
)(withStyles(styles)(withRouter(PatientOverview)));