import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
//import Typography from '@material-ui/core/Typography';

const AntTabs = withStyles((theme) => ({
    root: {
        borderBottom: '1px solid #d7d7d7',
    },
    indicator: {
        backgroundColor: '#f26b33',
    },
    '& div': {
        background: 'none'
    }
}))((props) => <Tabs {...props} />)
const AntTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        minWidth: 72,
        fontWeight: 500,
        marginRight: "40px",
        color: '#a2a0a0',
        padding: 0,
        fontSize: "24px",
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            color: '#a2a0a0',
            opacity: 1,
        },
        '&$selected': {
            color: '#333333',
            fontWeight: 500,
        },
        '&:focus': {
            color: '#333333',
        },
    },
    demo1: {
        background: 'none'
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    padding: {
        padding: theme.spacing(3),
    },
    demo1: {
        backgroundColor: theme.palette.background.paper,
    },
    demo2: {
        backgroundColor: '#2e1534',
    },
}));

export default function CustomizedTabs({ components, tabsStyle, tabStyle }) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <div className={classes.root}>
            <div >
                <AntTabs style={tabsStyle} value={value} onChange={handleChange} aria-label="ant example">
                    {
                        components.map(c => (
                            <AntTab style={tabStyle} label={`${c.label}`} />
                        ))
                    }
                </AntTabs>
                <div>
                    {components && components[value] && components[value].component}
                </div>
            </div>
        </div>
    );
}
