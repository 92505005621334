import React from 'react';
import { Pie } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

class PieChart extends React.Component {
    render() {
        const { width, height, data, poptions={} } = this.props
        let options = {
            legend: {
                display: false,
                position: "bottom",
                align: "start"
            },
            responsive: false,
            maintainAspectRatio: false
        };
        if(!isEmpty(poptions)){
            options=poptions;
        }
        return (
            <Pie
                data={data}
                width={width}
                height={height}
                options={options}
            />
        );
    }
};

PieChart.defaultProps = {
    data: {
        labels: [
            'CKD',
            'PD'
        ],
        datasets: [{
            data: [73, 17],
            backgroundColor: [
                '#089bab',
                '#f78b1a'
            ],
            hoverBackgroundColor: [
                '#089bab',
                '#f78b1a'
            ]
        }]
    },
    width: null,
    height: null
}
PieChart.propTypes = {
    data: PropTypes.object,
    width: PropTypes.number,
    height: PropTypes.number

}
export default PieChart;