import React from "react";
import { withRouter } from "react-router-dom";
import Snackbar from "./ui-containers/SnackBar";
//import CircularProgress from "@material-ui/core/CircularProgress";
import {localStorageGet, mapDispatchToProps} from "./ui-utils/commons";
import MainRoutes from "./ui-routes/MainRoutes";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Hidden from "@material-ui/core/Hidden";
import LoadingOverlay from "react-loading-overlay";
import Idlecontainer from "./ui-molecules/Idlecontainer";
import "./App.css";


class App extends React.Component {

  componentDidMount= async()=>{
    const {i18n,selectedLanguage}=this.props;
    i18n.changeLanguage(selectedLanguage);
  }

  render() {
    const { spinner, errorChk, setAppData } = this.props;
    return (
      <div>
        <Hidden only={["md", "lg", "xl"]}>
          <div className="mobile" />
        </Hidden>
        <Hidden only={["xs", "sm"]}>
          <LoadingOverlay active={spinner}>
            <div>
              <MainRoutes />
              {(localStorageGet("loggedIn") || errorChk) && (
                <Idlecontainer setAppData={setAppData} errorChk={errorChk} />
              )}
              <Snackbar />
              {}
            </div>
          </LoadingOverlay>
        </Hidden>
      </div>
    );
  }
}

const mapStateToProps = ({ screenConfiguration }) => {
  const { preparedFinalObject = {} } = screenConfiguration;
  const { spinner=false, selectedLanguage="en", errorChk} = preparedFinalObject;
  return { spinner, selectedLanguage, errorChk };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(App)));
