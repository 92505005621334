import { prepareFinalObject } from "../ui-redux/screen-configuration/actions";
import store from "../ui-redux/store";
import { get, isEmpty } from "lodash";
import moment from 'moment';

export const addQueryArg = (url, queries = []) => {
  const urlParts = url.split("?");
  const path = urlParts[0];
  let queryParts = urlParts.length > 1 ? urlParts[1].split("&") : [];
  queries.forEach(query => {
    const key = query.key;
    const value = query.value;
    const newQuery = `${key}=${value}`;
    queryParts.push(newQuery);
  });
  const newUrl = path + "?" + queryParts.join("&");
  return newUrl;
};
export const versionBuild=() =>{
return{
  Version :"v1.2-Test",
  Build:"1004-Test"
}
}
export const buildDateGreaterThan = (latestDate, currentDate) => {
  const momLatestDateTime = moment(latestDate);
  const momCurrentDateTime = moment(currentDate);
  if (momLatestDateTime.isAfter(momCurrentDateTime)) {
    return true;
  } else {
    return false;
  }
};
export const formatDefalutDate = (time, formats) => {
  if (!time || time === 'string') return null;
  const date = moment(Number(time)).format(formats);
  return date;
};
export const getUrlParameterValue = (key) => {
  let params = (new URL(document.location)).searchParams;
  let value = params.get(key);
  return value;
}

export const age = (dateString) => {
  let birth = new Date(dateString);
  let now = new Date();
  let beforeBirth = ((() => { birth.setDate(now.getDate()); birth.setMonth(now.getMonth()); return birth.getTime() })() < birth.getTime()) ? 0 : 1;
  return now.getFullYear() - birth.getFullYear() - beforeBirth;
}
const svgToDataUri = (svgStr) => {
  const encoded = encodeURIComponent(svgStr)
    .replace(/'/g, '%27')
    .replace(/"/g, '%22');

  const header = 'data:image/svg+xml;charset=utf8,';
  const dataUrl = header + encoded;

  return dataUrl;
};
export const svgReplaceColor = (svgStr, color) => {
  return svgToDataUri(svgStr.replace(/#xxxxxx/g, color));
};

export const checkArray = (array) => Array.isArray(array) && array.length;

export const showDevelopment = () => {
  store.dispatch(prepareFinalObject("snackbar", {
    open: true,
    variant: "info",
    message:
      "Feature Under Development"
  }));

}
const getUserDetails = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo")) || {};
  return userInfo.User;
};
const getAuthDetails = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo")) || {};
  return userInfo.OAuthDetails;
};


export const wrapRequestBody = (requestBody, action) => {
  const authToken = localStorageGet("userInfo", "OAuthDetails.authToken");
  const requestId = localStorageGet("userInfo", "User.uuid");
  let RequestInfo = {
    apiId: "AWAK_WEB",
    ver: "V1",
    ts: `${getDateInEpoch()}`,
    action: action,
    did: "1",
    key: "",
    msgId: "1605595169022|en_IN",
    requesterId: requestId,
    authToken: authToken
  };
  // if(isPublicSearch()) delete RequestInfo.authToken;
  if (action !== "_search") {
    return Object.assign(
      {},
      {
        RequestInfo,
      },
      requestBody
    );
  } else {
    return {
        RequestInfo,
      ...requestBody
    };
  }
};

export const wrapRequestBodyClinic = (requestBody, action, clinicUUID) => {
  const userDetail = getUserDetails();
  const authDetails = getAuthDetails();
  const { authToken } = authDetails;
  const { uuid } = userDetail;
  let RequestInfo = {
    apiId: "AWAK_WEB",
    ver: "V1",
    ts: `${getDateInEpoch()}`,
    action: action,
    did: "1",
    key: "",
    msgId: "string",
    clinicUUID: clinicUUID,
    requesterId: uuid,
    authToken: authToken,
  };

  return Object.assign(
    {},
    {
      RequestInfo
    },
    requestBody
  );
};
export const getDateInEpoch = () => {
  return new Date().toUTCString();
};
// export const getQueryArg = (url, name) => {
//   if (!url) url = window.location.href;
//   name = name.replace(/[\[\]]/g, "\\$&");
//   var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
//     results = regex.exec(url);
//   if (!results) return null;
//   if (!results[2]) return "";
//   return decodeURIComponent(results[2].replace(/\+/g, " "));
// };
export const backendHosts = () => {
  let backendHosts = {};
  const hostname = window && window.location && window.location.hostname;
  if (
    hostname.includes("staging") || hostname === "localhost"
  ) {
    backendHosts.AUTH_SERVICE =
      "https://awak-wk-auth-micro-service-staging.awakpd.com";
    backendHosts.USER_SERVICE =
      "https://awak-wk-user-micro-service-staging.awakpd.com";
    backendHosts.PATIENT_SERVICE =
      "https://awak-wk-patient-micro-service-staging.awakpd.com";
    backendHosts.CLINIC_SERVICE =
      "https://awak-wk-clinic-micro-service-staging.awakpd.com";
    backendHosts.CLINICIAN_SERVICE =
      "https://awak-wk-clinician-micro-service-staging.awakpd.com";
  } else if (hostname.includes("-qa")   ) {
    backendHosts.AUTH_SERVICE =
      "https://awak-wk-auth-micro-service-qa.awakpd.com";
    backendHosts.USER_SERVICE =
      "https://awak-wk-user-micro-service-qa.awakpd.com";
    backendHosts.PATIENT_SERVICE =
      "https://awak-wk-patient-micro-service-qa.awakpd.com";
    backendHosts.CLINIC_SERVICE =
      "https://awak-wk-clinic-micro-service-qa.awakpd.com";
    backendHosts.CLINICIAN_SERVICE =
      "https://awak-wk-clinician-micro-service-qa.awakpd.com";
  } else if (hostname.includes("demo")) {
    backendHosts.AUTH_SERVICE =
      "https://awak-wk-auth-micro-service-demo.awakpd.com";
    backendHosts.USER_SERVICE =
      "https://awak-wk-user-micro-service-demo.awakpd.com";
    backendHosts.PATIENT_SERVICE =
      "https://awak-wk-patient-micro-service-demo.awakpd.com";
    backendHosts.CLINIC_SERVICE =
      "https://awak-wk-clinic-micro-service-demo.awakpd.com";
    backendHosts.CLINICIAN_SERVICE =
      "https://awak-wk-clinician-micro-service-demo.awakpd.com";
  } else if (hostname.includes("dev")) {
    backendHosts.AUTH_SERVICE =
      "https://awak-wk-auth-micro-service-dev.awakpd.com";
    backendHosts.USER_SERVICE =
      "https://awak-wk-user-micro-service-dev.awakpd.com";
    backendHosts.PATIENT_SERVICE =
      "https://awak-wk-patient-micro-service-dev.awakpd.com";
    backendHosts.CLINIC_SERVICE =
      "https://awak-wk-clinic-micro-service-dev.awakpd.com";
    backendHosts.CLINICIAN_SERVICE =
      "https://awak-wk-clinician-micro-service-dev.awakpd.com";
  }else if (hostname === "localhost") {
     backendHosts.AUTH_SERVICE =
      "https://awak-wk-auth-micro-service-qa.awakpd.com";
    backendHosts.USER_SERVICE =
      "https://awak-wk-user-micro-service-qa.awakpd.com";
    backendHosts.PATIENT_SERVICE =
      "https://awak-wk-patient-micro-service-qa.awakpd.com";
    backendHosts.CLINIC_SERVICE =
      "https://awak-wk-clinic-micro-service-qa.awakpd.com";
    backendHosts.CLINICIAN_SERVICE =
      "https://awak-wk-clinician-micro-service-qa.awakpd.com";
  }
  else if (hostname.includes("loadtesting")) {
    backendHosts.AUTH_SERVICE =
      "http://awak-demo-auth-micro-service-loadtesting.awakpd.com";
    backendHosts.USER_SERVICE =
      " http://awak-demo-user-micro-service-loadtesting.awakpd.com";
    backendHosts.PATIENT_SERVICE =
      "http://awak-demo-patient-micro-service-loadtesting.awakpd.com";
    backendHosts.CLINIC_SERVICE =
      "http://awak-demo-clinic-micro-service-loadtesting.awakpd.com";
    backendHosts.CLINICIAN_SERVICE =
      "http://awak-demo-clinician-micro-service-loadtesting.awakpd.com";
  }
  else {
    backendHosts.AUTH_SERVICE =
      "https://auth-micro-service.wekare.sg";
    backendHosts.USER_SERVICE =
      "https://user-micro-service.wekare.sg";
    backendHosts.PATIENT_SERVICE =
      "https://patient-micro-service.wekare.sg";
    backendHosts.CLINIC_SERVICE =
      "https://clinic-micro-service.wekare.sg";
    backendHosts.CLINICIAN_SERVICE =
      "https://clinician-micro-service.wekare.sg";
  }
  return backendHosts;
}

export function capitalize(s){
  return s[0].toUpperCase() + s.slice(1);
}


export const getadminTableData = (datas = []) => {
  let tableData = [];
  let roledata = [];
  let roleObj = [];
  datas.length > 0 &&
    datas.forEach((clinician, key) => {
      if (clinician.userInfo) {
        const {
          uuid = "",
          firstname = "",
          lastname = "",
          roles = [],
          email = "",
          phone = "",
        } = clinician.userInfo || {};
        let arr1 = roles.filter((item) => item.uuid === "123456789018");
        let arr2 = [];
        if (arr1.length === 0) {
          arr2 = roles.filter((item) => item.name !== "123456789018");
          arr2.forEach((role, key) => {
            switch (role.uuid) {
              case `${role.uuid}`:
                if (
                  role.uuid !== "123456789018" ||
                  role.uuid !== "123456789012"
                ) {
                  if (!roleObj[clinician.uuid]) {
                    roledata.push({
                      label: firstname + " " + lastname,
                      value: clinician.uuid,
                    });
                    roleObj[clinician.uuid] = {
                      ...roleObj[clinician.uuid],
                      ...clinician,
                    };
                  }
                }
                break;
              default:
            }
          });
        } else {
          tableData[tableData.length] = [
            clinician.id ? clinician.id : uuid,
            capitalize(firstname),
            capitalize(lastname),
            getRole(roles),
            email,
            `+` +phone,
          ];
        }
      }
    });
  return {
    tableData: [...tableData],
    roleData: roledata,
    clinicans_array: roleObj,
  };
};

export const localStorageGet = (key, path) => {
  let value = null;
  if (path) {
    const data = JSON.parse(window.localStorage.getItem(key)) || null;
    value = get(data, path);
  } else {
    value = window.localStorage.getItem(key) || null;
  }

  return value;
};

export const formatCalendarDateSimple = (createdTime) => {
  if (!createdTime) return null;
  const date = new Date(Number(createdTime));
  return [date.getDate(), date.getMonth() + 1, date.getFullYear()];
};
export const mapDispatchToProps = (dispatch) => {
  return {
    setAppData: (jsonPath, data) => {
      dispatch(prepareFinalObject(jsonPath, data))
    }
  }
};
export const getPercentage = (data, total) => {
  if (Number(data) === 0 || Number(total) === 0) return 0;
  let data_label = Number(data) / Number(total);
  const result = data_label * 100;
  console.log({ result });
  return Math.round(result).toString().padStart(2, "0");
}
export const checkAdmin = (roles = []) => {
  const isAdmin = roles.find(role => role.uuid === "123456789018")
  return isAdmin ? 'Clinic Admin' : ""
}

export const getRole = (roles = []) => {
  const role = roles.filter(role => {return role.uuid !== "123456789013" && role.uuid !== "123456789018"});
  var roleName='';
  console.log(role);
  if(!isEmpty(role)){
    roleName=role[0].name;
    roleName =capitalize(roleName.toLowerCase());
  }
  return roleName;
}
export let snackbarObj = {};
snackbarObj.open = true;
snackbarObj.variant = "error";

export const createClinicRequestBody = (clinicParameters, CLINIC_ROLES) => {
  const { 
    name, 
    firstname, 
    lastname, 
    phoneWithDialCode, 
    mphone,
    role, 
    email, 
    address, 
    phone, 
    ephone, 
    phi,
    activeFeatureList
  } = clinicParameters;

  return {
    "Clinics": [
      {
        "name": name,
        "address": address,
        "phonenumber": `65${phone}`,
        "hotline": `65${ephone}`,
        "operatingHours": [
          {
            "from": "10:00",
            "to": "19:00"
          }
        ],
        "holidays": "sundays",
        "registrationDate": new Date().getTime(),
        "isPHI": phi,
        "userInfo": {
          "phone": phoneWithDialCode || mphone,
          "email": email,
          "firstname": firstname,
          "lastname": lastname,
          "password": "12345678",
          "gender": "MALE",
          "dob": 0,
          "photoURL": "string",
          "invitationType": "EMAIL",
          "isPhoneVarified": false,
          "isEmailVarified": false,
          "registrationDate": new Date().getTime(),
          "active": true,
          "roles": [
            {
              "uuid": "123456789013",
              "name": 'CLINICIAN',
              "active": true
            },
            {
              "uuid": "123456789018",
              "name": 'CLINICIAN-ADMIN',
              "active": true
            },
            {
              "uuid": `${CLINIC_ROLES[role.value]}`,
              "name": role.value,
              "active": true
            }

          ],
          "auditDetails": {
            "createdBy": "1234ADMINROLE",
            "lastModifiedBy": "1234ADMINROLE",
            "createdTime": 0,
            "lastModifiedTime": 0
          },
          "additionalInfo": {
            "permissions": [
              { "roleId": 0, "role": { "label": "Add New Clinicians in its Own Clinic", "value": true } },
              { "roleId": 1, "role": { "label": "Assign Admin Rights to Other Clinicians", "value": true } },
              { "roleId": 2, "role": { "label": "Create / Edit Patient Profile", "value": true } },
              { "roleId": 3, "role": { "label": "Manage Limits and Notifications for patients", "value": true } },
              { "roleId": 4, "role": { "label": "Review Medical Records", "value": true } },
              { "roleId": 5, "role": { "label": "Manage and Communicate with patients", "value": true } }
            ]
          }
        },
        "features": activeFeatureList,
        "additionalInfo": {
          "mdmsData": {
            "vitalmdms": {
              "vitalsmdmsUnits": {
                "weight": {
                  "label": "Kg",
                  "value": "kg"
                },
                "body_temperature": {
                  "label": "C",
                  "value": "c"
                },
                "blood_glucose": {
                  "label": "mmol/L",
                  "value": "mmol/L"
                },
                "blood_pressure": {
                  "label": "mmHg",
                  "value": "mmHg"
                },
                "diastolic_blood_pressure": {
                  "label": "mmHg",
                  "value": "mmHg"
                },
                "heart_rate": {
                  "label": "bpm",
                  "value": "bpm"
                },
                "respiratory_rate": {
                  "label": "bpm",
                  "value": "bpm"
                },
                "spo2": {
                  "label": "%",
                  "value": "%"
                },
                "height": {
                  "label": "m",
                  "value": "m"
                }
              },
              "vital_limits": {
                "weight_limit": {
                  "min": "58",
                  "max": "62",
                  "notify": true,
                  "frequency": { value: "3", label: "3" },
                  "period": { value: 2, label: "Days" }
                },
                "body_temperature_limit": {
                  "min": "32",
                  "max": "42",
                  "notify": true,
                  "frequency": { value: "3", label: "3" },
                  "period": { value: 2, label: "Days" }
                },
                "blood_pressure_limit": {
                  "min": "40",
                  "max": "260",
                  "notify": true,
                  "frequency": { value: "3", label: "3" },
                  "period": { value: 2, label: "Days" }
                },
                "diastolic_blood_pressure_limit": {
                  "min": "40",
                  "max": "260",
                  "notify": true,
                  "frequency": { value: "3", label: "3" },
                  "period": { value: 2, label: "Days" }
                },
                "body_pressure_limit": {
                  "min": "86",
                  "max": "86",
                  "notify": true,
                  "frequency": { value: "3", label: "3" },
                  "period": { value: 2, label: "Days" }
                },
                "heart_rate_limit": {
                  "min": "40",
                  "max": "180",
                  "notify": true,
                  "frequency": { value: "3", label: "3" },
                  "period": { value: 2, label: "Days" }
                },
                "respiratory_rate_limit": {
                  "min": "8",
                  "max": "35",
                  "notify": true,
                  "frequency": { value: "3", label: "3" },
                  "period": { value: 2, label: "Days" }
                },
                "spo2_limit": {
                  "min": "88",
                  "max": "100",
                  "notify": true,
                  "frequency": { value: "3", label: "3" },
                  "period": { value: 2, label: "Days" }
                },
                "blood_glucose_limit": {
                  "min": "1.1",
                  "max": "3.3",
                  "notify": true,
                  "frequency": { value: "3", label: "3" },
                  "period": { value: 2, label: "Days" }
                }
              }
            },
            "symptoms_mdms": {
              "symptoms_limit": {
                "others": {
                  "label": "Mild",
                  "value": 3
                },
                "fatigue": {
                  "label": "Mild",
                  "value": 3
                },
                "sleeplessness": {
                  "label": "Mild",
                  "value": 3
                },
                "swelling": {
                  "label": "Mild",
                  "value": 3
                },
                "shortness_of_breath": {
                  "label": "Mild",
                  "value": 3
                },
                "muscle_cramps": {
                  "label": "Mild",
                  "value": 3
                },
                "nausea": {
                  "label": "Mild",
                  "value": 3
                },
                "loss_of_apetite": {
                  "label": "Mild",
                  "value": 3
                },
                "itching": {
                  "label": "Mild",
                  "value": 3
                },
                "constipation": {
                  "label": "Mild",
                  "value": 3
                },
                "dizziness": {
                  "label": "Mild",
                  "value": 3
                },
                "stress": {
                  "label": "Mild",
                  "value": 3
                }
              }
            },
            "therapy_mdms": {
              "therapy_limit": {
                "ultrafiltration_limit": {
                  "min": "-2500",
                  "max": "2500"
                },
                "exit_site": {
                  "value": "CLEAN_AND_DRY",
                  "label": "Clean And Dry"
                },
                "drain_color": {
                  "value": "#F7F2B5",
                  "label": "Light yellow"
                },
                "drain_clarity": {
                  "value": "SLIGHTLY_CLOUDY",
                  "label": "Slightly Cloudy"
                },
                "drain_foreign_object": {
                  "value": "FIBRIN",
                  "label": "Fibrin"
                },
                "pain_score": {
                  "value": "3",
                  "label": "3"
                }
              }
            },
            "dateTimeFormat": {
              "dateformat": {
                "value": "DD-MMM-YYYY",
                "label": "DD-MMM-YYYY"
              },
              "timeformat": {
                "value": "hh:mm a",
                "label": "12 Hours"
              }
            },
            "nameFormat": {
              "value": "last_name_first",
              "label": "Last name first"
            },
            "default_notifications": {
              "vitals": [
                {
                  "frequency": { "label": 3, "value": 3 },
                  "max": 62,
                  "min": 58,
                  "period": { "value": 1, "label": "Episode" },
                  "toNotify": true,
                  "vital": { "label": "Weight Limit", "value": "weight_limit" }
                },
                {
                  "frequency": { "label": 3, "value": 3 },
                  "limit": "32-42",
                  "max": 42,
                  "min": 32,
                  "period": { "value": 2, "label": "Days" },
                  "toNotify": true,
                  "vital": { "label": "Body Temperature Limit", "value": "body_temperature_limit" }
                },
                {
                  "frequency": { "label": 3, "value": 3 },
                  "limit": "40-260",
                  "max": 260,
                  "min": 40,
                  "period": { "value": 2, "label": "Days" },
                  "toNotify": true,
                  "vital": { "label": "blood pressure limit", "value": "blood_pressure_limit" }
                },
                {
                  "frequency": { "label": 3, "value": 3 },
                  "limit": "40-260",
                  "max": 260,
                  "min": 40,
                  "period": { "value": 2, "label": "Days" },
                  "toNotify": true,
                  "vital": { "label": "diastolic blood pressure limit", "value": "diastolic_blood_pressure_limit" }
                },
                {
                  "frequency": { "label": 3, "value": 3 },
                  "limit": "86-86",
                  "max": 86,
                  "min": 86,
                  "period": { "value": 2, "label": "Days" },
                  "toNotify": true,
                  "vital": { "label": "body pressure limit", "value": "body_pressure_limit" }
                },
                {
                  "frequency": { "label": 3, "value": 3 },
                  "limit": "40-180",
                  "max": 180,
                  "min": 40,
                  "period": { "value": 2, "label": "Days" },
                  "toNotify": true,
                  "vital": { "label": "heart rate limit", "value": "heart_rate_limit" }
                },
                {
                  "frequency": { "label": 3, "value": 3 },
                  "limit": "8-35",
                  "max": 35,
                  "min": 8,
                  "period": { "value": 2, "label": "Days" },
                  "toNotify": true,
                  "vital": { "label": "respiratory rate limit", "value": "respiratory_rate_limit" }
                },
                {
                  "frequency": { "label": 3, "value": 3 },
                  "limit": "88-100",
                  "max": 100,
                  "min": 88,
                  "period": { "value": 2, "label": "Days" },
                  "toNotify": true,
                  "vital": { "label": "spo2 limit", "value": "spo2_limit" }
                },
                {
                  "frequency": { "label": 3, "value": 3 },
                  "limit": "1.1-3.3",
                  "max": 33,
                  "min": 1.1,
                  "period": { "value": 2, "label": "Days" },
                  "toNotify": true,
                  "vital": { "label": "blood glucose limit", "value": "blood_glucose_limit" }
                }
              ],
              "symptoms": [
                {
                  "frequency": { "label": 3, "value": 3 },
                  "limit": { label: "Mild", value: 3 },
                  "period": { "value": 2, "label": "Days" },
                  "isNotify": true,
                  "vital": { "label": "others", "value": "others" }
                },
                {
                  "frequency": { "label": 3, "value": 3 },
                  "limit": { label: "Mild", value: 3 },
                  "period": { "value": 2, "label": "Days" },
                  "isNotify": true,
                  "vital": { "label": "fatigue", "value": "fatigue" }
                },
                {
                  "frequency": { "label": 3, "value": 3 },
                  "limit": { label: "Mild", value: 3 },
                  "period": { "value": 2, "label": "Days" },
                  "isNotify": true,
                  "vital": { "label": "sleeplessness", "value": "sleeplessness" }
                },
                {
                  "frequency": { "label": 3, "value": 3 },
                  "limit": { label: "Mild", value: 3 },
                  "period": { "value": 2, "label": "Days" },
                  "isNotify": true,
                  "vital": { "label": "swelling", "value": "swelling" }
                },
                {
                  "frequency": { "label": 3, "value": 3 },
                  "limit": { label: "Mild", value: 3 },
                  "period": { "value": 2, "label": "Days" },
                  "isNotify": true,
                  "vital": { "label": "shortness of breath", "value": "shortness_of_breath" }
                },
                {
                  "frequency": { "label": 3, "value": 3 },
                  "limit": { label: "Mild", value: 3 },
                  "period": { "value": 2, "label": "Days" },
                  "isNotify": true,
                  "vital": { "label": "muscle cramps", "value": "muscle_cramps" }
                },
                {
                  "frequency": { "label": 3, "value": 3 },
                  "limit": { label: "Mild", value: 3 },
                  "period": { "value": 2, "label": "Days" },
                  "isNotify": true,
                  "vital": { "label": "nausea", "value": "nausea" }
                },
                {
                  "frequency": { "label": 3, "value": 3 },
                  "limit": { label: "Mild", value: 3 },
                  "period": { "value": 2, "label": "Days" },
                  "isNotify": true,
                  "vital": { "label": "loss of apetite", "value": "loss_of_apetite" }
                },
                {
                  "frequency": { "label": 3, "value": 3 },
                  "limit": { label: "Mild", value: 3 },
                  "period": { "value": 2, "label": "Days" },
                  "isNotify": true,
                  "vital": { "label": "itching", "value": "itching" }
                },
                {
                  "frequency": { "label": 3, "value": 3 },
                  "limit": { label: "Mild", value: 3 },
                  "period": { "value": 2, "label": "Days" },
                  "isNotify": true,
                  "vital": { "label": "constipation", "value": "constipation" }
                },
                {
                  "frequency": { "label": 3, "value": 3 },
                  "limit": { label: "Mild", value: 3 },
                  "period": { "value": 2, "label": "Days" },
                  "isNotify": true,
                  "vital": { "label": "dizziness", "value": "dizziness" }
                },
                { "vital": { "label": "stress", "value": "stress" }, "limit": { label: "Mild", value: 3 }, "frequency": { "label": 3, "value": 3 }, "period": { "value": 2, "label": "Days" }, "isNotify": true }
              ],
              "therapy": {
                "ultrafiltration_limit": {
                  "frequency": {
                    "label": 3,
                    "value": 3
                  },
                  "limit": {
                    "label": "-2500-2500",
                    "value": "-2500-2500"
                  },
                  "max": 2500,
                  "min": -2500,
                  "period": {
                    "value": 2,
                    "label": "Days"
                  },
                  "toNotify": true,
                  "vital": {
                    "label": "ultraFiltration",
                    "value": "ultraFiltration"
                  }
                },
                "exit_site": {
                  "frequency": {
                    "label": 3,
                    "value": 3
                  },
                  "limit": {
                    "value": "CLEAN_AND_DRY",
                    "label": "Clean And Dry"
                  },
                  "period": {
                    "value": 2,
                    "label": "Days"
                  },
                  "toNotify": true,
                  "vital": {
                    "label": "Exit Site",
                    "value": "exitSite"
                  }
                },
                "drain_color": {
                  "frequency": {
                    "label": 3,
                    "value": 3
                  },
                  "limit": {
                    "value": "#F7F2B5",
                    "label": "Light yellow"
                  },
                  "period": {
                    "value": 2,
                    "label": "Days"
                  },
                  "toNotify": true,
                  "vital": {
                    "label": "Colour",
                    "value": "colour"
                  }
                },
                "drain_clarity": {
                  "frequency": {
                    "label": 3,
                    "value": 3
                  },
                  "limit": {
                    "value": "SLIGHTLY_CLOUDY",
                    "label": "Slightly Cloudy"
                  },
                  "period": {
                    "value": 2,
                    "label": "Days"
                  },
                  "toNotify": true,
                  "vital": {
                    "label": "Clarity",
                    "value": "clarity"
                  }
                },
                "drain_foreign_object": {
                  "frequency": {
                    "label": 3,
                    "value": 3
                  },
                  "limit": {
                    "value": "FIBRIN",
                    "label": "Fibrin"
                  },
                  "period": {
                    "value": 2,
                    "label": "Days"
                  },
                  "toNotify": true,
                  "vital": {
                    "label": "Foreign Object",
                    "value": "foreignObject"
                  }
                },
                "pain_score": {
                  "frequency": {
                    "label": "3",
                    "value": "3"
                  },
                  "limit": {
                    "value": "3",
                    "label": "3"
                  },
                  "period": {
                    "value": 2,
                    "label": "Days"
                  },
                  "toNotify": true,
                  "vital": {
                    "label": "Pain Score",
                    "value": "painScore"
                  }
                }
              }
            },
            "flaggingSystem": [
            ],
            "defaultPermissions": {
              "admin": [
                { "roleId": 0, "role": { "label": "Add New Clinicians in its Own Clinic", "value": true } },
                { "roleId": 1, "role": { "label": "Assign Admin Rights to Other Clinicians", "value": true } },
                { "roleId": 2, "role": { "label": "Create / Edit Patient Profile", "value": true } },
                { "roleId": 3, "role": { "label": "Manage Limits and Notifications for patients", "value": true } },
                { "roleId": 4, "role": { "label": "Review Medical Records", "value": true } },
                { "roleId": 5, "role": { "label": "Manage and Communicate with patients", "value": true } }
              ],
              "doctor": [
                { "roleId": 0, "role": { "label": "Add New Clinicians in its Own Clinic", "value": false } },
                { "roleId": 1, "role": { "label": "Assign Admin Rights to Other Clinicians", "value": false } },
                { "roleId": 2, "role": { "label": "Create / Edit Patient Profile", "value": true } },
                { "roleId": 3, "role": { "label": "Manage Limits and Notifications for patients", "value": true } },
                { "roleId": 4, "role": { "label": "Review Medical Records", "value": true } },
                { "roleId": 5, "role": { "label": "Manage and Communicate with patients", "value": true } }
              ],
              "nurse": [
                { "roleId": 0, "role": { "label": "Add New Clinicians in its Own Clinic", "value": false } },
                { "roleId": 1, "role": { "label": "Assign Admin Rights to Other Clinicians", "value": false } },
                { "roleId": 2, "role": { "label": "Create / Edit Patient Profile", "value": true } },
                { "roleId": 3, "role": { "label": "Manage Limits and Notifications for patients", "value": true } },
                { "roleId": 4, "role": { "label": "Review Medical Records", "value": true } },
                { "roleId": 5, "role": { "label": "Manage and Communicate with patients", "value": true } }
              ],
              "dietition": [
                { "roleId": 0, "role": { "label": "Add New Clinicians in its Own Clinic", "value": false } },
                { "roleId": 1, "role": { "label": "Assign Admin Rights to Other Clinicians", "value": false } },
                { "roleId": 2, "role": { "label": "Create / Edit Patient Profile", "value": false } },
                { "roleId": 3, "role": { "label": "Manage Limits and Notifications for patients", "value": false } },
                { "roleId": 4, "role": { "label": "Review Medical Records", "value": true } },
                { "roleId": 5, "role": { "label": "Manage and Communicate with patients", "value": true } }
              ],
              "pharmacists": [
                { "roleId": 0, "role": { "label": "Add New Clinicians in its Own Clinic", "value": false } },
                { "roleId": 1, "role": { "label": "Assign Admin Rights to Other Clinicians", "value": false } },
                { "roleId": 2, "role": { "label": "Create / Edit Patient Profile", "value": false } },
                { "roleId": 3, "role": { "label": "Manage Limits and Notifications for patients", "value": false } },
                { "roleId": 4, "role": { "label": "Review Medical Records", "value": true } },
                { "roleId": 5, "role": { "label": "Manage and Communicate with patients", "value": true } }
              ]
            }
            // "defaultPermissions": {
            //   "admin": [
            //     { "roleId": 0, "role": { "label": "Add New Clinicians in its Own Clinic", "value": true } },
            //     { "roleId": 1, "role": { "label": "Assign Admin Rights to Other Clinicians", "value": true } },
            //     { "roleId": 2, "role": { "label": "Create / Edit Patient Profile", "value": true } },
            //     { "roleId": 3, "role": { "label": "Create / Update Patient PD Prescription", "value": true } },
            //     { "roleId": 4, "role": { "label": "Create / Update Patient Medication Prescriptions", "value": true } },
            //     { "roleId": 5, "role": { "label": "Define / Update Reporting Thresholds", "value": true } },
            //     { "roleId": 6, "role": { "label": "Access AWAK Customer Care", "value": true } },
            //     { "roleId": 7, "role": { "label": "Review Patient Therapy Records", "value": true } },
            //     { "roleId": 8, "role": { "label": "Review Patient Vital Records", "value": true } },
            //     { "roleId": 9, "role": { "label": "Review Patient Diet Records", "value": true } },
            //     { "roleId": 10, "role": { "label": "Manage Appointment with Patient", "value": true } },
            //     { "roleId": 11, "role": { "label": "Communication with Patients", "value": true } },
            //     { "roleId": 12, "role": { "label": "Access AWAK Online Educational Portal", "value": true } }
            //   ],
            //   "doctor": [
            //     { "roleId": 0, "role": { "label": "Add New Clinicians in its Own Clinic", "value": false } },
            //     { "roleId": 1, "role": { "label": "Assign Admin Rights to Other Clinicians", "value": false } },
            //     { "roleId": 2, "role": { "label": "Create / Edit Patient Profile", "value": true } },
            //     { "roleId": 3, "role": { "label": "Create / Update Patient PD Prescription", "value": true } },
            //     { "roleId": 4, "role": { "label": "Create / Update Patient Medication Prescriptions", "value": true } },
            //     { "roleId": 5, "role": { "label": "Define / Update Reporting Thresholds", "value": true } },
            //     { "roleId": 6, "role": { "label": "Access AWAK Customer Care", "value": true } },
            //     { "roleId": 7, "role": { "label": "Review Patient Therapy Records", "value": true } },
            //     { "roleId": 8, "role": { "label": "Review Patient Vital Records", "value": true } },
            //     { "roleId": 9, "role": { "label": "Review Patient Diet Records", "value": true } },
            //     { "roleId": 10, "role": { "label": "Manage Appointment with Patient", "value": false } },
            //     { "roleId": 11, "role": { "label": "Communication with Patients", "value": true } },
            //     { "roleId": 12, "role": { "label": "Access AWAK Online Educational Portal", "value": true } }
            //   ],
            //   "nurse": [
            //     { "roleId": 0, "role": { "label": "Add New Clinicians in its Own Clinic", "value": false } },
            //     { "roleId": 1, "role": { "label": "Assign Admin Rights to Other Clinicians", "value": false } },
            //     { "roleId": 2, "role": { "label": "Create / Edit Patient Profile", "value": true } },
            //     { "roleId": 3, "role": { "label": "Create / Update Patient PD Prescription", "value": true } },
            //     { "roleId": 4, "role": { "label": "Create / Update Patient Medication Prescriptions", "value": true } },
            //     { "roleId": 5, "role": { "label": "Define / Update Reporting Thresholds", "value": true } },
            //     { "roleId": 6, "role": { "label": "Access AWAK Customer Care", "value": true } },
            //     { "roleId": 7, "role": { "label": "Review Patient Therapy Records", "value": true } },
            //     { "roleId": 8, "role": { "label": "Review Patient Vital Records", "value": true } },
            //     { "roleId": 9, "role": { "label": "Review Patient Diet Records", "value": true } },
            //     { "roleId": 10, "role": { "label": "Manage Appointment with Patient", "value": false } },
            //     { "roleId": 11, "role": { "label": "Communication with Patients", "value": true } },
            //     { "roleId": 12, "role": { "label": "Access AWAK Online Educational Portal", "value": true } }
            //   ],
            //   "dietition": [
            //     { "roleId": 0, "role": { "label": "Add New Clinicians in its Own Clinic", "value": false } },
            //     { "roleId": 1, "role": { "label": "Assign Admin Rights to Other Clinicians", "value": false } },
            //     { "roleId": 2, "role": { "label": "Create / Edit Patient Profile", "value": false } },
            //     { "roleId": 3, "role": { "label": "Create / Update Patient PD Prescription", "value": false } },
            //     { "roleId": 4, "role": { "label": "Create / Update Patient Medication Prescriptions", "value": false } },
            //     { "roleId": 5, "role": { "label": "Define / Update Reporting Thresholds", "value": false } },
            //     { "roleId": 6, "role": { "label": "Access AWAK Customer Care", "value": true } },
            //     { "roleId": 7, "role": { "label": "Review Patient Therapy Records", "value": true } },
            //     { "roleId": 8, "role": { "label": "Review Patient Vital Records", "value": true } },
            //     { "roleId": 9, "role": { "label": "Review Patient Diet Records", "value": true } },
            //     { "roleId": 10, "role": { "label": "Manage Appointment with Patient", "value": false } },
            //     { "roleId": 11, "role": { "label": "Communication with Patients", "value": true } },
            //     { "roleId": 12, "role": { "label": "Access AWAK Online Educational Portal", "value": true } }
            //   ],
            //   "pharmacists": [
            //     { "roleId": 0, "role": { "label": "Add New Clinicians in its Own Clinic", "value": false } },
            //     { "roleId": 1, "role": { "label": "Assign Admin Rights to Other Clinicians", "value": false } },
            //     { "roleId": 2, "role": { "label": "Create / Edit Patient Profile", "value": false } },
            //     { "roleId": 3, "role": { "label": "Create / Update Patient PD Prescription", "value": false } },
            //     { "roleId": 4, "role": { "label": "Create / Update Patient Medication Prescriptions", "value": true } },
            //     { "roleId": 5, "role": { "label": "Define / Update Reporting Thresholds", "value": false } },
            //     { "roleId": 6, "role": { "label": "Access AWAK Customer Care", "value": true } },
            //     { "roleId": 7, "role": { "label": "Review Patient Therapy Records", "value": true } },
            //     { "roleId": 8, "role": { "label": "Review Patient Vital Records", "value": true } },
            //     { "roleId": 9, "role": { "label": "Review Patient Diet Records", "value": false } },
            //     { "roleId": 10, "role": { "label": "Manage Appointment with Patient", "value": false } },
            //     { "roleId": 11, "role": { "label": "Communication with Patients", "value": true } },
            //     { "roleId": 12, "role": { "label": "Access AWAK Online Educational Portal", "value": true } }
            //   ]
            // }
          }
        },
        "auditDetails": {
          "createdBy": "1234ADMINROLE",
          "lastModifiedBy": "1234ADMINROLE",
          "createdTime": 0,
          "lastModifiedTime": 0
        }
      }
    ],
  }
}
