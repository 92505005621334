import React from "react";
import { Route, Redirect } from "react-router";
import Loadable from "react-loadable";
import Loading from "../ui-molecules/Loading";
import { localStorageGet } from "../ui-utils/commons";
import PatientOverview from "../ui-pages/UserHome/components/Content/Patients/PatientOverview";

const Login = Loadable({
  loader: () => import("../ui-pages/Login"),
  loading: Loading
});

const Dashboard = Loadable({
  loader: () => import("../ui-pages/UserHome/index"),
  loading: Loading
});

const ResetPassword = Loadable({
  loader: () => import("../ui-pages/Login/components/ResetPassword/ResetPassword"),
  loading: Loading
});

const ForgotPassword = Loadable({
  loader: () => import("../ui-pages/Login/components/ForgotPassword/ForgotPassword"),
  loading: Loading
});

const SetupAccount = Loadable({
  loader: () => import("../ui-pages/Login/components/SetupAccount/SetupAccount"),
  loading: Loading
});

const AddPatient = Loadable({
  loader: () => import("../ui-pages/UserHome/components/Content/Clinics/addPatient"),
  loading: Loading
});

const PatientSummary = Loadable({
  loader: () => import("../ui-pages/UserHome/components/Content/Clinics/components/TabContent/Patients/patientSummary"),
  loading: Loading
})

const Admin = Loadable({
  loader: () => import("../ui-pages/UserHome/components/Content/Administration/admin"),
  loading: Loading
});
const HelpCenter = Loadable({
  loader: () => import("../ui-pages/UserHome/components/Content/HelpCenter"),
  loading: Loading
})

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    localStorageGet("loggedIn") === "true" && localStorageGet("userInfo", "OAuthDetails.createdAt") &&  (new Date().getTime().valueOf() - localStorageGet("userInfo", "OAuthDetails.createdAt") < 30*60*1000)
      ? <Component {...props} />
      : <Redirect to='/' />
  )} />
)

const MainRoutes = () => {
  return (
    <div>
      <Route exact path="/" component={Login} />     {/*Login component should be changed back to Dashboard*/}
      <PrivateRoute path="/user-home" component={Dashboard} />
      <PrivateRoute path="/login" component={Login} />
      <Route path="/reset-password" component={ResetPassword} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/setup-account" component={SetupAccount} />
      <PrivateRoute path="/addPatient" component={AddPatient} />
      <PrivateRoute path="/patient-summary" component={PatientSummary} />
      <PrivateRoute path="/patient-overview" component={PatientOverview} />
      <PrivateRoute path="/user-home/admin" component={Admin} />
      <PrivateRoute path="/user-home/helpcenter" component={HelpCenter} />
      <PrivateRoute exact path="/user-home/clinic/patient-summary/:clinicId/:clinicName/:patientId" component={PatientSummary} />
      {/* <Redirect to="/login" /> */}
    </div>
  )
}

export default MainRoutes;
