import React, {useState, useEffect} from 'react';
import {  Button, Dialog, DialogActions, DialogContent, DialogTitle, withStyles } from "@material-ui/core";
import { withRouter } from 'react-router-dom';

const styles = ({
    cancel_button: {
        width: "91px",
        height: "40px",
        borderRadius: "12px",
        border: "solid 1px #089bab",
        boxShadow: "none"
    },
    cancel_text: {
        width: "59px",
        height: "24px",
        fontFamily: "'Open Sans'",
        fontSize: "16px",
        fontWeight: 600,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.5",
        letterSpacing: "normal",
        textAlign: "center",
        textTransform: "none",
        color: " #089bab"
    },
    deleteText: {
        width: "56px",
        height: "24px",
        fontFamily: "'Open Sans'",
        fontSize: "16px",
        fontWeight: 600,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.5",
        letterSpacing: "normal",
        textAlign: "center",
        color: "#ffffff",
        textTransform: "none"
    },
    deleteButton: {
        width: "85px",
        borderRadius: "12px",
        background: "linear-gradient(#FF0000 30%, #FF0000)",
        height: "40px"
    }
});
function IdleMonitor({classes, setAppData, history,  errorChk})
{
  //Modal
  const [idleModal, setIdleModal] = useState(false);

  let idleTimeout = 1000 * 60 * 30;  //1 minute
  let idleEvent; 

  /**
   * Add any other events listeners here
   */
  const events = [
    'mousemove',
    'click',
    'keypress'
  ];

  /**
   * @method sessionTimeout
   * This function is called with each event listener to set a timeout or clear a timeout.
   */
  const sessionTimeout = () => 
  {
    const myStorage = window.localStorage; 
    if(!!idleEvent) clearTimeout(idleEvent);

    if(myStorage.getItem('loggedIn') === 'true'){
      idleEvent = setTimeout(() => triggerPopup(), idleTimeout); //show session warning modal.
    }else{
      idleEvent = false;
    }
  };

  const triggerPopup = () =>{
    if(window.localStorage.getItem('loggedIn') === 'true'){
      setIdleModal(true);
    }
  }
  /**
   * @method logOut
   * This function will destroy current user session.
   */
  const logOut = () => 
  {
    setAppData('communication.video', false);
    setAppData('communication.token',null);
    setAppData('administratorSummary',{});
    setAppData('clinicianPortal',{});
    setAppData('communication',{});
    setAppData('dashboard',{});
    setAppData('mevents',[]);
    setAppData('patientPortal',{});
    setAppData('patients',{});
    setAppData('savepatient',{});
    setAppData('userProfile', '');
    setAppData('errorChk', false);
    setIdleModal(false);
    history.push('/login');
    window.localStorage.clear();
  }

  useEffect (() =>{
    if(errorChk){
     setIdleModal(true);
    }
   
  },[errorChk]);

  useEffect(() => 
  {
    for (let e in events) 
    {
      window.addEventListener(events[e], sessionTimeout);
    }

    return () => 
    {
      for(let e in events)
      {
        window.removeEventListener(events[e], sessionTimeout);
      }
    }
    // eslint-disable-next-line
  },[]);


    return (

      <Dialog open={idleModal} toggle={() => setIdleModal(false)}>
        <DialogTitle toggle={() => setIdleModal(false)}>
            {`Session Expired`}
        </DialogTitle>
        <DialogContent>
           Your session has expired. Please login to renew session.
        </DialogContent>
        <DialogActions>
            <Button className={classes.deleteButton} onClick={()=>logOut()}>
                <span className={classes.deleteText}>Login</span>
            </Button>
        </DialogActions>
      </Dialog>
    )

  }

export default (withRouter(withStyles(styles)(IdleMonitor)));